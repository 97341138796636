import liff from "@line/liff"
import axios from "axios"
import router from "@/router"
import Swal from 'sweetalert2'
import { setTitle, setFavicon, setMeta } from "./../store/modules/liff/helpers";

export default class LiffChecker {
  constructor(orgCode, options = { notLogin: false }) {
    this.orgCode = orgCode;
    this.initData = {};
    this.notLogin = options.notLogin;
    this.logined = false;
  }

  getInitData = async () => {
    let response = await axios.get(process.env.VUE_APP_API_BASE_URL + '/' + this.orgCode + '/liff/init');
    return response.data.data;
  }

  initLiff = async () => {
    if (process.env.VUE_APP_MOCK_VUEX_LIFF_INFO) {
      // local 開發專用
      // 1. mock init API
      let info = JSON.parse(process.env.VUE_APP_MOCK_VUEX_LIFF_INFO)
      this.liffId = info.liff_id
      this.initData = info
      this.setMetaFromInitData()
      // 2. do not redirect to LINE login
      return
    }

    // 如果遇到 liff 的驗證失敗，要有相容機制
    const queryParams = new URLSearchParams(window.location.search);

    // Check if error query parameter exists
    const hasErrorParam = queryParams.has('error');

    if (hasErrorParam) {
      // Remove error and error_message parameters from the query parameters
      queryParams.delete('error');
      queryParams.delete('error_description');
      queryParams.delete('state');
      queryParams.delete('liffOAuth2Error');

      // Reconstruct the URL without error and error_message parameters
      const cleanUrl = `${window.location.pathname}?${queryParams.toString()}`;

      window.location.href = cleanUrl;

      await new Promise(() => {});
    }

    await axios.get(process.env.VUE_APP_API_BASE_URL + '/' + this.orgCode + '/liff/init').then(response => {
      if (response.data.data.liff_id) {
        this.liffId = response.data.data.liff_id;
        this.initData = response.data.data;
      } else {
        throw "Liff not found";
      }
    }).catch(() => {
      this.liffFailed()
    })

    await liff.init({
      liffId: this.liffId
    }).then(() => {
      if (! liff.isLoggedIn()) {
        liff.login({
          redirectUri: window.location.href
        })
      }
    }).catch(() => {
      this.liffFailed()
    });

    // 檢查是否加好友並強迫加入OA好友
    // let { friendFlag } = await liff.getFriendship();

    // if (! friendFlag) {
    //   console.error('尚未成為OA好友，或是封鎖');

    //   await Swal.fire({
    //     title: '提醒',
    //     text: '您尚未加入此官方帳號好友',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonText: '加入好友',
    //     cancelButtonText: '取消',
    //     preConfirm: async () => {
    //       window.open('https://page.line.me/?accountId=' + this.initData.line_oa_id, '_blank');
    //       return await this.checkFriendLoop();
    //     }
    //   }).then(async (result) => {
    //     console.log('result', result);
    //     // 根據操作結果執行相應的操作
    //     if (result.value) {
    //       // 如果確認按鈕被點擊且操作成功，則執行後續操作
    //       // 這裡可以放置您要執行的後續操作代碼
    //       console.log('確認按鈕被點擊且操作成功');
    //     } else {
    //       const queryParams = new URLSearchParams(window.location.search);

    //       queryParams.delete('code');
    //       queryParams.delete('state');
    //       queryParams.delete('liffClientId');
    //       queryParams.delete('liffRedirectUri');

    //       // Reconstruct the URL without error and error_message parameters
    //       const cleanUrl = `${window.location.origin}${window.location.pathname}?${queryParams.toString()}`;

    //       liff.login({
    //         redirectUri: cleanUrl
    //       })
    //       await new Promise(() => {})
    //     }
    //   });
    // }

    this.setMetaFromInitData()
  }

  checkFriendLoop = async () => {
    /*eslint no-constant-condition: ["off"]*/
    do {
      await this.sleep(1000)
      let { friendFlag } = await liff.getFriendship();
      console.log(friendFlag);
      if (friendFlag) return true;
    } while (true);
  }

  setMetaFromInitData = () => {
    if (this.initData.liff_og_title) {
      setTitle(this.initData.liff_og_title);
      setMeta("og:site_title", this.initData.liff_og_title);
    }
    if (this.initData.liff_icon_url) {
      setFavicon(this.initData.liff_icon_url);
    }
    if (this.initData.liff_og_image) {
      setMeta("og:image", this.initData.liff_og_image);
    }
    if (this.initData.liff_og_description) {
      setMeta("og:description", this.initData.liff_og_description);
    }
  }

  portalCheck = async () => {
    let lineData = await this.getLineData()
    let params = new URL(location.href).searchParams;

    // Check this LINE ID binding status
    await axios.post(process.env.VUE_APP_API_BASE_URL + '/' + this.orgCode +'/liff/portal', {
      line_id: lineData.profile.userId,
      line_access_token: lineData.accessToken,
      redirect_url: params.get('redirect_url') ?? null,
    }).then(response => {
      if (response.data.status === 'failed') {
        router.push('/' + this.orgCode + '/liff/login')
      }
      if (response.data.status === 'success' && response.data.data.url) {
        window.location.href = response.data.data.url
      }
    }).catch(error => {
      console.log(error)
      router.push('/' + this.orgCode + '/liff/login')
    })
  }

  getLineData = async () => {
    try {
      await this.initLiff()

      if (process.env.VUE_APP_MOCK_VUEX_LINE_PROFILE && process.env.VUE_APP_MOCK_VUEX_ACCESS_TOKEN) {
        // local 開發專用
        return {
          profile: JSON.parse(process.env.VUE_APP_MOCK_VUEX_LINE_PROFILE),
          accessToken: process.env.VUE_APP_MOCK_VUEX_ACCESS_TOKEN,
        }
      }

      return {
        profile: await liff.getProfile(),
        accessToken: liff.getAccessToken(),
      }
    } catch (error) {
      console.error('get line data error', error);

      const removeItems = ['context', 'IDToken', 'clientId', 'accessToken', 'decodedIDToken'];
      removeItems.forEach(async (item) => {
        localStorage.removeItem(`LIFF_STORE:${this.liffId}:${item}`);
      });

      liff.login({
        redirectUri: window.location.href
      })
      await new Promise(() => {})
    }
  }

  bindCheck = async () => {
    let params = new URL(location.href).searchParams

    if (params.get('type') !== 'ec' || params.get('token') === null) {
      router.push('/' + this.orgCode + '/liff/login')
      return
    }

    let lineData = await this.getLineData()

    await axios.post(process.env.VUE_APP_API_BASE_URL + '/' + this.orgCode + '/liff/bind', {
      line_id: lineData.profile.userId,
      line_access_token: lineData.accessToken,
      token: params.get('token'),
      redirect_url: params.get('redirect_url') ?? null,
    }).then(response => {

      if (response.data.status === 'success' && response.data.data.url) {
        window.location.href = response.data.data.url
        return;
      }
      router.push('/' + this.orgCode + '/liff/login')
    }).catch(async error => {
      if (error.response.data.status === 'failed') {
        if (error.response.data.message) {
          await Swal.fire({
            title: '錯誤',
            type: 'error',
            html: error.response.data.message
          });
        }
      }
      router.push('/' + this.orgCode + '/liff/login')
    });
  }

  liffFailed() {
    alert('初始化頁面失敗，請確認此網址是否正確')
  }

  sleep = async (t) => {
    await new Promise(resolve => { setTimeout(resolve, t) })
  }
}
